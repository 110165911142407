/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  new WOW().init();

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        //Remove modal click for pp on touch devices
        if ($('html').hasClass('touchevents')) {
          $('a#sa-pp-modal-btn').removeAttr('data-toggle').removeAttr('data-target');
        }


        //Run preloader
        setTimeout(function () {
          $('span.sa-logo-animated svg path').addClass('loaded');
          setTimeout(function () {
            $('div.preloader-outer').fadeTo(850, 0.0001, function(){
              $(this).remove();
            });
          }, 850);
        }, 850);


        //Navigation scroll events
        $(window).scroll(function () {
          if ($(".header-nav").offset().top > 60) {
            $(".header-nav").addClass("navbar-show");
          } else {
            $(".header-nav").removeClass("navbar-show");
          }

          //Set the active nav
          $('section.sa-section').each(function(){

            var offset = 150;
            var ost = (parseInt($(this).offset().top) - offset);
            var h = $(this).height();
            var st = $(window).scrollTop();
            var id = $(this).attr('id');

            if ( (st >= ost) && (st <= (ost + h)) ) {
              $('ul#menu-menu-main li a[href="#'+id+'"]').addClass('active');
              $(this).addClass('active');
            } else {
              $('ul#menu-menu-main li a[href="#'+id+'"]').removeClass('active');
              $(this).removeClass('active');
            }

            if( (st + $(window).height()) === $(document).height()) {
              $('ul#menu-menu-main li a[href="#footer"]').addClass('active');
            } else {
              $('ul#menu-menu-main li a[href="#footer"]').removeClass('active');
            }
          });
        });


        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function(){
          $(this).toggleClass('is-active');
          $('ul#menu-menu-1').toggleClass('nav-active');
          $('div.navbar-collapse').toggleClass('collapse');
        });


        //Navigation scroll to
        $(".page-scroll a, a.page-scroll").bind("click", function (e) {
          var t = $(this);

          if (t.text() === 'Contact') {
            $("html, body").stop().animate({
              scrollTop: $('body').height()
            }, 1500, "easeInOutExpo");
          } else {
            $("html, body").stop().animate({
              scrollTop: (parseInt($(t.attr("href")).offset().top) - 60)
            }, 1500, "easeInOutExpo");
          }


          e.preventDefault();
        });


        //Projects gallery carousel
        if ($('div.sa-gallery-carousel').length) {
          $('div.sa-gallery-carousel').owlCarousel({
            items: 4,
            nav: false,
            dots: true,
            margin: 5,
            autoplay: true,
            autoplayHoverPause: true,
            autoplaySpeed: 1200,
            //loop: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 3
              },
              768: {
                items: 4
              }
            }
          });
        }//end project gallery carousel


        //Mobile nav click - close navigation menu
        $('ul#menu-menu-main li.page-scroll').click(function(){
          if ($(window).width() <= 812 && !$('div.navbar-collapse').hasClass('collapse')) {
            $('div.hamburger').trigger('click');
          }
        });


        //Project gallery popup
        $('.sa-gallery-carousel').each(function(){
          $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: true,
            midClick: true,
            gallery:{
              enabled: true,
              preload: [0,2]
            }
          });
        });


        //Bootstrap modal:
        $('#person-modal').on('show.bs.modal', function (e) {

          var $btn = $(e.relatedTarget),
              $p = $btn.parent('.sa-people-wrap'),
              img_src = $p.find('img').attr('src'),
              img_alt = $p.find('img').attr('alt'),
              title = $p.find('h4').html(),
              bio = $p.find('p').text(),
              modal = $(this);

          if ($p.find('div.img-wrap').hasClass('sa-img-hide')) {
            modal.find('.sa-modal-img-wrap').addClass('sa-img-hide');
          } else {
            modal.find('.sa-modal-img-wrap').removeClass('sa-img-hide');
          }


          //Get the person data and populate the modal before showing:
          modal.find('#person-modal-label').html(title);
          modal.find('.sa-modal-img-wrap img').attr('src', img_src).attr('alt', img_alt);
          modal.find('.sa-modal-bio-wrap p').text(bio);

        });


        //PP modal
        $('#pp-modal').on('show.bs.modal', function (e) {
          var iframe = $(this).find('iframe');
          if (iframe.attr('src') === '') {
            iframe.attr('src', iframe.data('src'));
          }
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
